<template>
    <main>

      <div v-if="loading && !requiredFields.id">
        <b-skeleton width="100%" height="400px" />
      </div>
      <section v-else class="required-fields">
        <div class="required-fields__title-container">
          <span class="required-fields-title">Configurações</span>
        </div>
        <div>
          <toggle-button
            :sync="true"
            :height="24"
            class="mr-2"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
            v-model="requiredFields.guide_conference"
          />
          <span>Conferência de guias <InfoIcon id="info-guias" class="cursor-pointer" /></span>
          <b-tooltip v-if="!loading" target="info-guias" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
            Ao habilitar a “Conferência de guias” todas as guias terão um campo para checagem na aba “Relatório de guias” e na aba “Lotes a enviar” terão a porcentagem de guias conferidas.
          </b-tooltip>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">GUIA BPA <InfoIcon id="info-badge" class="cursor-pointer" /></span>
          <b-tooltip v-if="!loading" target="info-badge" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
            Selecione os campos que deverão ser obrigatórios o preenchimento para gerar as guias BPA relacionadas ao convênio SUS. Os campos não selecionados serão opcionais.
          </b-tooltip>
          <span>Campos obrigatórios:</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.name_establishment_bpa">
              <span>Nome do estabelecimento de saúde</span>
            </div>
            <div class="item">
              <input :disabled="true"  class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cnes_bpa">
              <span>CNES</span>
            </div>
            <div class="item">
              <input :disabled="true"  class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.national_card_bpa">
              <span>Cartão Nacional de Saúde (CNS)</span>
            </div>
            <div class="item">
              <input :disabled="true"  class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cbo_bpa">
              <span>CBO</span>
            </div>
            <div class="item">
              <input :disabled="true"  class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.team_bpa">
              <span>Equipe</span>
            </div>
            <div class="item">
              <input :disabled="true" checked class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.year_month_bpa">
              <span>Ano/Mês</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.patient_name_bpa">
              <span>Nome do paciente</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.date_birth_bpa">
              <span>Data de nascimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.nationality_bpa">
              <span>Nacionalidade</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.race_color_bpa">
              <span>Raça/Cor</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.ethnicity_bpa">
              <span>Etnia</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cep_bpa">
              <span>CEP</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.ibge_bpa">
              <span>Cód. IBGE Município</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.street_code_bpa">
              <span>Cód. Logradouro</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.address_bpa">
              <span>Logradouro</span>
            </div>            
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.number_bpa">
              <span>Número</span>
            </div>            
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.complement_bpa">
              <span>Complemento</span>
            </div>            
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.neighborhood_bpa">
              <span>Bairro</span>
            </div>            
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.contact_telephone_bpa">
              <span>Telefone de contato</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.e_mail_bpa">
              <span>E-mail</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.attendance_date_bpa">
              <span>Data de atendimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.procedure_bpa">
              <span>Cód do procedimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.qtde_bpa">
              <span>Qtde.</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cnpj_bpa">
              <span>CNPJ</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.service_bpa">
              <span>Serviço</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.class_bpa">
              <span>Class</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cid_bpa">
              <span>CID</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.character_service_bpa">
              <span>Caráter de atendimento</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.authorization_bpa">
              <span>N° da autorização</span>
            </div>
          </div>
        </div>
        <div class="required-fields__category">
          <span class="required-fields__category__title">Guia APAC <InfoIcon id="info-badge-2" class="cursor-pointer" /></span>
          <b-tooltip v-if="!loading" target="info-badge-2" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
            Selecione os campos que deverão ser obrigatórios o preenchimento para gerar as guias APAC relacionadas ao convênio SUS. Os campos não selecionados serão opcionais.
          </b-tooltip>
          <span>Campos obrigatórios:</span>
          <div class="required-fields__category__items">
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.name_establishment_apac">
              <span>Nome do estabelecimento de saúde</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cnes_applicant_apac">
              <span>CNES estabelecimento solicitante</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.patient_name_apac">
              <span>Nome do paciente</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.request_apac">
              <span>N° da solicitação</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.national_card_apac">
              <span>Cartão Nacional de Saúde (CNS)</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.date_birth_apac">
              <span>Data de nascimento</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.sex_apac">
              <span>Sexo</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.race_color_apac">
              <span>Raça/Cor</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.ethnicity_apac">
              <span>Etnia</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.mother_name_apac">
              <span>Nome da mãe</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.mother_contact_telephone_apac">
              <span>Telefone de contato da mãe</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.name_responsible_apac">
              <span>Nome do responsávesl</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.responsible_contact_telephone_apac">
              <span>Telefone de contato do responsável</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.address_apac">
              <span>Endereço (Rua, N°, Bairro)</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.municipality_apac">
              <span>Município de residência</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.ibge_apac">
              <span>Cód. IBGE Município</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.state_apac">
              <span>Estado</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cep_apac">
              <span>CEP</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cod_main_procedure_apac">
              <span>Cód. do procedimento principal</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.name_main_procedure_apac">
              <span>Nome do procedimento principal</span>
            </div>
            <div class="item">
              <input :disabled="true" class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.qtde_main_apac">
              <span>Qtde. procedimento principal</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cod_secondary_procedure_apac">
              <span>Cód. do procedimento secundário</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.name_secondary_procedure_apac">
              <span>Nome do procedimento secundário</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.qtde_secondary_apac">
              <span>Qtde. procedimento secundário</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cid_main_apac">
              <span>CID 10 Principal</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cid_secondary_apac">
              <span>CID 10 Secundário</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cod_main_procedure_justification_apac">
              <span>Cód. do procedimento principal justificativa</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.reason_leaving_staying_apac">
              <span>Motivo de saída/permanência</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.diagnosis_apac">
              <span>Descrição do diagnóstico</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.observations_apac">
              <span>Observações</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.professional_name_apac">
              <span>Nome do profissional solicitante</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.request_date_apac">
              <span>Data de solicitação</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.national_requester_card_apac">
              <span>Cartão Nacional do Profissional Solicitante</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.authorizing_professional_apac">
              <span>Nome do profissional autorizador</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.issuer_apac">
              <span>Cód. Orgão Emissor</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.national_authorizer_card_apac">
              <span>Cartão Nacional do Profissional Autorizador</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.authorization_date_apac">
              <span>Data de autorização</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.authorization_apac">
              <span>N° da autorização (APAC)</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.validity_period_apac">
              <span>Período de validade de APAC</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.fantasy_name_apac">
              <span>Nome fantasia do estab. de saúde executante</span>
            </div>
            <div class="item">
              <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="requiredFields.cnes_executant_apac">
              <span>CNES estabelecimento executante</span>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
<script>
    import { mapState } from 'vuex'
    import api from '@/modules/sus/manageGuides/api'
    import { getCurrentClinic } from '@/utils/localStorageManager'
    export default {
        components: {
            InfoIcon: () => import('@/assets/icons/info-icon.svg')
        },
        computed: {
            ...mapState('manageGuidesSus', ['requiredFields']),            
        },
        created() {
          // Configurações fixas
          this.requiredFields.cnes_bpa = true;
          this.requiredFields.national_card_bpa = true;
          this.requiredFields.cbo_bpa = true;
          this.requiredFields.team_bpa = true;
          this.requiredFields.year_month_bpa = true;
          this.requiredFields.name_establishment_apac = true;
          this.requiredFields.cnes_applicant_apac = true;
          this.requiredFields.patient_name_apac = true;
          this.requiredFields.request_apac = true;
          this.requiredFields.national_card_apac = true;
          this.requiredFields.date_birth_apac = true;
          this.requiredFields.sex_apac = true;
          this.requiredFields.mother_name_apac = true;
          this.requiredFields.mother_contact_telephone_apac = true;
          this.requiredFields.address_apac = true;
          this.requiredFields.municipality_apac = true;
          this.requiredFields.state_apac = true;
          this.requiredFields.cep_apac = true;
          this.requiredFields.cod_main_procedure_apac = true;
          this.requiredFields.name_main_procedure_apac = true;
          this.requiredFields.qtde_main_apac = true;
        },
        data() {
            return {
                loading: false,
                clinic: getCurrentClinic(),
            }
        },
        methods: {
            updateSusGuideRequiredFields() {
                this.loading = true
                api.updateSusGuideRequiredFields(this.requiredFields.id, this.requiredFields)
                    .then(() => {})
                    .catch(err => this.$toast.error(err.message))
                    .finally(() => (this.loading = false))
            }
        },
        watch: {
            requiredFields: {
                async handler() {
                    this.updateSusGuideRequiredFields()
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss" scoped>
.required-fields {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;

  &__title-container {
    display: flex;
    justify-content: space-between;

    .required-fields-title {
      font-size: 16px;
      font-weight: 600;
      color: #142666;
    }

    .delete-settings {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #305BF2;
      cursor: pointer;

      svg {
        width: 16px;
        height: 20px;
        fill: #305BF2;
      }
    }
  }

  &__category {
    &__title {
      font-weight: 600;
    }

    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 335px;

        input {
          width: 16px;
          height: 16px;
        }

        span {
          display: flex;
          height: 24px;
          flex-direction: column;
          justify-content: center;
          font-weight: 400;
        }
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>